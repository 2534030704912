<template>
<v-container>
    <div>
        <div id="stepExp1" v-if="countPreferencesData == 1" class="px-3 pt-10">
            <p class="text-center black--text font-weight-bold subtitle-1 mb-0 mx-8">
                ¿Cuál es el rango de horario en el que quisieras trabajar?
            </p>
            <p class="caption">Si estudias, elige un rango que no interfiera con tu horario</p>
            <v-form ref="form" v-model="addPersonalData.datosValidPer">
                <v-select
                @change="selectHoursAnalytics"
                    v-model="addPreferencesData.range"
                    class="grey-label"
                    label="Rango horario"
                    outlined
                    color="teal"
                    placeholder="Seleccionar"
                    :items="scheduleRange"
                    :rules="[(v) => !!v || 'Selecciona un rango de horario']"
                ></v-select>
                <div v-if="addPersonalData.provincia === 'A'">
                    <p class="text-center black--text font-weight-bold subtitle-1 mb-0 mx-8">
                    ¿En qué distrito te gustaría trabajar?
                    </p>
                    <p class="caption">Selecciona hasta 2 opciones que prefieras</p>
                    <v-row class="mt-2">
                    <v-checkbox
                    @click="()=> selectDistrictAnalytics('Lima')"
                        v-model="addPreferencesData.district"
                        color="teal"
                        label="Cercado de Lima"
                        value="Cercado de Lima"
                    ></v-checkbox>
                    <div @click="showCercadoMap()" class="text-link">Ver</div>
                </v-row>
                <v-row class="mt-2">
                    <v-checkbox
                    @click="()=> selectDistrictAnalytics('Surquillo')"
                        v-model="addPreferencesData.district"
                        color="teal"
                        label="Surquillo"
                        value="Surquillo"
                    ></v-checkbox>
                    <div @click="showSurquilloMap()"  class="text-link">Ver</div>
                </v-row>
                <v-row class="mt-2">
                    <v-checkbox
                    @click="()=> selectDistrictAnalytics('Callao')"
                        v-model="addPreferencesData.district"
                        color="teal"
                        label="Callao"
                        value="Callao"
                        :rules="[
                            () => addPreferencesData.district.length > 0
                            || 'Selecciona al menos una opción',
                            () => addPreferencesData.district.length < 3
                            || 'Elige sólo dos opciones'
                        ]"
                    ></v-checkbox>
                    <div @click="showCallaoMap()" class="text-link">Ver</div>
                </v-row>
                </div>
                <p class="text-center black--text font-weight-bold subtitle-1 mt-3 mx-8">
                    ¿En qué servicio te gustaría trabajar?
                </p>
                <p class="caption">Selecciona hasta 2 opciones que prefieras</p>
                <v-row class="mt-2">
                    <v-checkbox
                    @click="()=>selectServiceAnalytics('AtencionAlCliente')"
                    v-model="addPreferencesData.service"
                    color="teal"
                    label="Atención al cliente"
                    value="Atención al cliente"
                    ></v-checkbox>
                    <div @click="openCustomerServiceModal()" class="text-link">Descripción</div>
                </v-row>
                <v-row class="mt-2">
                    <v-checkbox
                    @click="()=>selectServiceAnalytics('Ventas')"
                    v-model="addPreferencesData.service"
                    color="teal"
                    label="Ventas"
                    value="Ventas"
                    ></v-checkbox>
                    <div @click="openSaleModal()" class="text-link">Descripción</div>
                </v-row>
                <v-row>
                    <v-checkbox
                    @click="()=>selectServiceAnalytics('Bilingue')"
                    v-model="addPreferencesData.service"
                    color="teal"
                    label="Bilingüe"
                    value="Bilingüe"
                    ></v-checkbox>
                    <div @click="openBilingualModal()" class="text-link">Descripción</div>
                </v-row>
                <v-row>
                    <v-checkbox
                    @click="()=>selectServiceAnalytics('Cobranzas')"
                    v-model="addPreferencesData.service"
                    color="teal"
                    label="Cobranzas"
                    value="Cobranzas"
                    ></v-checkbox>
                    <div @click="openCollectionsModal()" class="text-link">Descripción</div>
                </v-row>
                <v-row>
                    <v-checkbox
                    @click="()=>selectServiceAnalytics('Retenciones')"
                    v-model="addPreferencesData.service"
                    color="teal"
                    label="Retenciones"
                    value="Retenciones"
                    ></v-checkbox>
                    <div @click="openWithholdingsModal()" class="text-link">Descripción</div>
                </v-row>
                <v-row>
                    <v-checkbox
                    @click="()=>selectServiceAnalytics('Crosseling')"
                    v-model="addPreferencesData.service"
                    color="teal"
                    label="Crosselling"
                    value="Crosseling"
                    ></v-checkbox>
                    <div @click="openCrosselingModal()" class="text-link">Descripción</div>
                </v-row>
                <v-row>
                    <v-checkbox
                    @click="()=>selectServiceAnalytics('BackOffice')"
                    v-model="addPreferencesData.service"
                    color="teal"
                    label="Back Office"
                    value="Back Office"
                    ></v-checkbox>
                    <div @click="openBackOfficeModal()" class="text-link">Descripción</div>
                </v-row>
                <v-row>
                    <v-checkbox
                    @click="()=>selectServiceAnalytics('Socialmedia')"
                    v-model="addPreferencesData.service"
                    color="teal"
                    label="Social media"
                    value="Social media"
                    :rules="[
                    () => addPreferencesData.service.length > 0 || 'Selecciona al menos una opción',
                    () => addPreferencesData.service.length < 3 || 'Elige sólo dos opciones'
                    ]"
                    ></v-checkbox>
                    <div @click="openSocialMediaModal()" class="text-link">Descripción</div>
                </v-row>
                <p class="text-center black--text font-weight-bold subtitle-1 mt-3 mx-8">
                    ¿Cuál es tu trabajo de preferencia?
                </p>
                <div>
                    <v-radio-group
                    @change="changeWorkPreferencesAnalytics"
                    hide-details
                    v-model="addPreferencesData.modality"
                    class="body-1 my-radio-group"
                    :rules="[(v) => !!v || 'Selecciona una opción']"
                    required
                >
                    <v-radio label="Presencial" value="Presencial" color="teal" class="py-2 mr-9">
                    </v-radio>
                    <v-radio label="Trabajo remoto" value="Trabajo remoto" color="teal" class="py-2"
                    ></v-radio>
                </v-radio-group>

                </div>
                <div class="rounded mt-7" style="border: 1px solid #1890FF; border-color: #1890FF;">
                    <v-row class="d-flex justify-center">
                        <v-col cols="1">
                            <v-icon color="#FFC107" class="px-2 pt-6">mdi-information</v-icon>
                        </v-col>
                        <v-col>
                            <p style="color: #1890FF;"
                            class="text-subtitle-2 pt-4 pb-2">Haremos lo posible para ofrecerte la
                            mejor opción
                            que se ajuste a tus preferencias.</p>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <div id="stepExp1" v-if="countPreferencesData == 2" class="px-3 pt-10">
            <v-form ref="form" v-model="addPersonalData.datosValidPer">
                <p class="text-center black--text font-weight-bold subtitle-1 mb-0 mx-2">
                ¿Cuentas con servicio fijo de internet en tu hogar a través de
                un router (cable/inalámbrico)?
                </p>
                <p class="caption">No aplica para conexiones de paquetes de datos</p>
                <v-radio-group
                @change="confirmWifiAnalytics"
                    v-model="addPreferencesData.internet"
                    class="body-1"
                    :rules="[(v) => !!v || 'Selecciona una opción']"
                    required
                >
                    <v-radio label="Sí" value="Si" color="teal" class="pa-2">
                    </v-radio>
                    <v-radio label="No" value="No" color="teal" class="pa-2"
                    ></v-radio>
                </v-radio-group>
                <div v-if="addPreferencesData.internet === 'Si'">
                    <p class="text-center black--text font-weight-bold subtitle-1 mb-0 mx-2">
                        ¿Cuál es la velocidad de tu conexión a internet?
                    </p>
                    <v-radio-group
                        v-model="addPreferencesData.megas"
                        class="body-1"
                        :rules="[(v) => !!v || 'Selecciona una opción']"
                        required
                    >
                    <v-radio label="Más de 5 MB" value="Más de 5 MB" color="teal" class="pa-2">
                    </v-radio>
                    <v-radio label="Más de 10 MB" value="Más de 10 MB" color="teal" class="pa-2">
                    </v-radio>
                    <v-radio label="Más de 20 MB" value="Más de 20 MB" color="teal" class="pa-2">
                    </v-radio>
                    <v-radio label="Más de 30 MB" value="Más de 30 MB" color="teal" class="pa-2">
                    </v-radio>
                </v-radio-group>
                </div>
                <p class="text-center black--text font-weight-bold subtitle-1 mb-0 mx-2">
                ¿Cuentas con auriculares?
                </p>
                <v-radio-group
                    v-model="addPreferencesData.headphones"
                    class="body-1"
                    :rules="[(v) => !!v || 'Selecciona una opción']"
                    required
                >
                    <v-radio label="Sí" value="Si" color="teal" class="pa-2">
                    </v-radio>
                    <v-radio label="No" value="No" color="teal" class="pa-2"
                    ></v-radio>
                </v-radio-group>
            </v-form>
        </div>
        <div id="stepExp1" v-if="countPreferencesData == 3" class="px-3 pt-10">
            <v-form ref="form" v-model="addPersonalData.datosValidPer">
                <p class="text-center black--text font-weight-bold subtitle-1 mb-0 mx-2">
                    ¿Con qué dispositivo te conectas a internet desde tu casa?
                </p>
                <v-radio-group
                @change="changeDeviceAnalitycs"
                    v-model="addPreferencesData.device"
                    class="body-1"
                    :rules="[(v) => !!v || 'Selecciona una opción']"
                    required
                >
                    <v-radio label="Laptop" value="Laptop" color="teal" class="pa-2">
                    </v-radio>
                    <v-radio label="Computadora" value="Computadora" color="teal" class="pa-2"
                    ></v-radio>
                    <v-radio label="Ninguno" value="Ninguno" color="teal" class="pa-2"
                    ></v-radio>
                </v-radio-group>
                <div v-if="addPreferencesData.device != 'Ninguno'">
                    <p class="text-center black--text font-weight-bold subtitle-1 my-2 mx-2">
                    ¿Cuánto de memoria RAM tiene tu computadora o Laptop?
                    </p>
                    <v-radio-group
                        v-model="addPreferencesData.ram"
                        class="body-1"
                        :rules="[(v) => !!v || 'Selecciona una opción']"
                        required
                    >
                        <v-radio label="2 GB" value="2 GB" color="teal" class="pa-2"> </v-radio>
                        <v-radio label="4 GB" value="4 GB" color="teal" class="pa-2"> </v-radio>
                        <v-radio label="6 GB" value="6 GB" color="teal" class="pa-2"> </v-radio>
                        <v-radio label="8 GB" value="8 GB" color="teal" class="pa-2"> </v-radio>
                        <v-radio label="Ninguno" value="Ninguno" color="teal" class="pa-2"> </v-radio>
                    </v-radio-group>
                </div>
                <v-expand-transition-group>
                    <div v-if="addPreferencesData.ram !== 'Ninguno' && addPreferencesData.device != 'Ninguno'" class="px-6 my-4">
                        <p class="text-center black--text font-weight-bold subtitle-1 my-2 mx-2">
                            ¿Qué tipo de procesador tienes?
                        </p>
                        <v-select
                            v-model="addPreferencesData.processor"
                            label="Procesador"
                            class="grey-label"
                            outlined
                            color="teal"
                            placeholder="Seleccionar"
                            :items="processorList"
                            :rules="[(v) => !!v || 'Selecciona un tipo de procesador']"
                        ></v-select>
                        <div class="rounded px-4 py5" style="border: 1px solid rgba(12, 83, 183, 1); border-color: rgba(12, 83, 183, 1);">
                        <v-row class="d-flex justify-center mt-1">
                            <v-col cols="1">
                                <v-icon color="rgba(12, 83, 183, 1)" class="px-2">mdi-alert-circle</v-icon>
                            </v-col>
                            <v-col>
                              <a target="_blank"
                              class="text-decoration-none"
                              href="https://www.intel.la/content/www/xl/es/support/articles/000006059/processors.html">
                                <p style="color: rgba(12, 83, 183, 1);">¿Cómo saber qué procesador tengo?</p>
                              </a>
                            </v-col>
                    </v-row>
                </div>
                    </div>
                </v-expand-transition-group>
            </v-form>
        </div>
        <DescriptionServiceModal
            v-model="descriptionDialog"
            :title="title"
            :content="content"
        >
        </DescriptionServiceModal>
        <MapCercadoModal
            v-model="mapCercadoDialog"
        >
        </MapCercadoModal>
        <MapSurquilloModal
            v-model="mapSurquilloDialog"
        >
        </MapSurquilloModal>
        <MapCallaoModal
            v-model="mapCallaoDialog"
        >
        </MapCallaoModal>
    </div>
    </v-container>
</template>

<script>

export default {
  components: {
    DescriptionServiceModal: () => import('../description_service_modal.vue'),
    MapCercadoModal: () => import('../Maps/map_cercado_modal.vue'),
    MapSurquilloModal: () => import('../Maps/map_surquillo_modal.vue'),
    MapCallaoModal: () => import('../Maps/map_callao_modal.vue'),
  },
  props: {
    countPreferencesData: {
      type: Number,
    },
    addPreferencesData: {
      type: Object,
    },
    addPersonalData: {
      type: Object,
    },
  },
  data() {
    return {
      distrito: 'Cercado de Lima',
      scheduleRange: [
        '04:00 - 16:00',
        '08:00 - 19:00',
        '10:00 - 20:00',
        '12:00 - 22:00',
        '13:00 - 00:00',
      ],
      processorList: [
        'AMD',
        'Intel Celeron',
        'Intel Core i3',
        'Intel Core i5',
        'Intel Core i7',
        'Ninguno',
      ],
      descriptionDialog: false,
      title: '',
      content: '',
      mapCercadoDialog: false,
      mapSurquilloDialog: false,
      mapCallaoDialog: false,
    };
  },
  methods: {
    openCustomerServiceModal() {
      this.title = 'Atención al cliente';
      this.content = 'Se encarga de la atención al cliente con el propósito de brindar solución a los problemas o inconvenientes mostrados.';
      this.descriptionDialog = true;
    },
    openSaleModal() {
      this.title = 'Ventas';
      this.content = 'Se encarga de realizar llamadas salientes a diversos clientes con el propósito de ofrecer y concretar la venta de los productos y/o servicios de la campaña.';
      this.descriptionDialog = true;
    },
    openCrosselingModal() {
      this.title = 'Crosselling';
      this.content = 'Se encarga de la atención al cliente con el propósito de brindar solución a los problemas o inconvenientes mostrados, y ofrecer productos y/o servicios disponibles de la campaña.';
      this.descriptionDialog = true;
    },
    openSocialMediaModal() {
      this.title = 'Social media';
      this.content = 'Se encarga de la atención al cliente con el propósito de brindar solución a los problemas o inconvenientes mostrados, y ofrecer productos y/o servicios disponibles de la campaña, a través de canales escritos.';
      this.descriptionDialog = true;
    },
    openBilingualModal() {
      this.title = 'Bilingüe';
      this.content = 'Se encarga de la atención al cliente en otro idioma, con el propósito de brindar solución a los problemas o inconvenientes mostrados, y ofrecer productos y/o servicios disponibles de la campaña.';
      this.descriptionDialog = true;
    },
    openBackOfficeModal() {
      this.title = 'Back Office';
      this.content = 'Se encarga de los procesos relacionados a la gestión interna del servicio en aspectos administrativos asociados al seguimiento de las actividades propias de la campaña.';
      this.descriptionDialog = true;
    },
    openWithholdingsModal() {
      this.title = 'Retenciones';
      this.content = 'Se encarga de la atención al cliente que ha sido transferido con una intención de dar de baja a sus productos y/o servicios, con el objetivo de poder revertir la decisión y retenerlos.';
      this.descriptionDialog = true;
    },
    openCollectionsModal() {
      this.title = 'Cobranzas';
      this.content = 'Se encarga del llamado y seguimiento de clientes con deuda pendiente, tiene como objetivo pactar un compromiso de pago dentro de un plazo establecido.';
      this.descriptionDialog = true;
    },
    showCercadoMap() {
      this.mapCercadoDialog = true;
    },
    showSurquilloMap() {
      this.mapSurquilloDialog = true;
    },
    showCallaoMap() {
      this.mapCallaoDialog = true;
    },
    changeDeviceAnalitycs(device) {
      this.$gtag.event(`LP_${device}`, {
        event_category: `LP_${device}`,
        event_label: `LP_${device}`,
        value: 1,
      });
    },
    confirmWifiAnalytics(response) {
      this.$gtag.event(`LP_Internet${response}`, {
        event_category: `LP_Internet${response}`,
        event_label: `LP_Internet${response}`,
        value: 1,
      });
    },
    changeWorkPreferencesAnalytics(preference) {
      this.$gtag.event(`LP_Trabajo${preference}`, {
        event_category: `LP_Trabajo${preference}`,
        event_label: `LP_Trabajo${preference}`,
        value: 1,
      });
    },
    selectServiceAnalytics(service) {
      this.$gtag.event(`LP_Servicio_${service.replaceAll(' ', '')}`, {
        event_category: `LP_Servicio_${service.replaceAll(' ', '')}`,
        event_label: `LP_Servicio_${service.replaceAll(' ', '')}`,
        value: 1,
      });
    },
    selectDistrictAnalytics(dis) {
      this.$gtag.event(`LP_Sede_${dis}`, {
        event_category: `LP_Sede_${dis}`,
        event_label: `LP_Sede_${dis}`,
        value: 1,
      });
    },
    selectHoursAnalytics(range) {
      this.$gtag.event(`LP_Horario_${range}`, {
        event_category: `LP_Horario_${range}`,
        event_label: `LP_Horario_${range}`,
        value: 1,
      });
    },
  },
};
</script>

<style scoped>
.text-link {
    color: rgba(51, 102, 255, 1);
    text-decoration: underline;
    cursor: pointer;
    font-size: 13px;

}

.lowercase {
    text-transform: lowercase;
}

.no-padding {
    padding: 0px 0px 0px 0px !important;
}

::v-deep .my-radio-group .v-input__slot {
  padding: 0;
}

</style>
